import React from 'react';

import Layout from '../../components/layout';
import SEO from '../../components/seo';
import slide1 from '../../../static/assets/slider/desktop/slide-1.png';
import slide2 from '../../../static/assets/slider/desktop/slide-2.png';
import slide3 from '../../../static/assets/slider/desktop/slide-3.png';
import slide4 from '../../../static/assets/slider/desktop/slide-4.png';
import slide5 from '../../../static/assets/slider/desktop/slide-5.png';
import slide6 from '../../../static/assets/slider/desktop/slide-6.png';
import slide7 from '../../../static/assets/slider/desktop/slide-7.png';
import slide8 from '../../../static/assets/slider/desktop/slide-8.png';
import slide9 from '../../../static/assets/slider/desktop/slide-9.png';

const DesktopPage = () => (
  <Layout>
    <SEO title="EasyBusy Desktop: Make achieving easier!" />

    <div className={'call-to-action'}>
      <div className={'container'}>
        <div className={'call-to-action__content'}>
          <h2>Download desktop app</h2>
          <p>
            Install our app on you computer and sync all your data with your mobile
            devices or across multiple computers.
          </p>
          <p>Current version: 1.0.5</p>

          <div className={'buttons-list'}>
            <div className={'button'}>
              <a
                href="https://easybusydo.com/releases/EasyBusy-Setup-v1.0.5.exe"
                target={'_blank'}
                rel="noreferrer"
              >
                <i className="fab fa-windows" />
                Windows
              </a>
            </div>

            <div className={'button'}>
              <a
                href="https://easybusydo.com/releases/EasyBusy-v1.0.5.dmg"
                target={'_blank'}
                rel="noreferrer"
              >
                <i className="fab fa-apple" />
                Mac
              </a>
            </div>

            <div className={'button'}>
              <a
                href="https://easybusydo.com/releases/EasyBusy-linux-i386-v1.0.5.deb"
                target={'_blank'}
                rel="noreferrer"
              >
                <i className="fab fa-linux" />
                Linux (i386)
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className={'page-header home'}>
      <img alt={'slide1'} src={slide1} />
      <img alt={'slide2'} src={slide2} />
      <img alt={'slide3'} src={slide3} />
      <img alt={'slide4'} src={slide4} />
      <img alt={'slide5'} src={slide5} />
      <img alt={'slide6'} src={slide6} />
      <img alt={'slide7'} src={slide7} />
      <img alt={'slide9'} src={slide9} />
      <img alt={'slide8'} src={slide8} />
    </div>

    {/*<div className={'container'}>*/}
    {/*  <div className={'features'}>*/}
    {/*    <div className={'feature__item'}>*/}
    {/*      <div className={'row'}>*/}
    {/*        <div className={'col-6 first'}>*/}
    {/*          <div className={'thumbnail'}>*/}
    {/*            <img alt={'Event'} src={slide2} />*/}
    {/*          </div>*/}
    {/*        </div>*/}

    {/*        <div className={'col-6'}>*/}
    {/*          <div className={'feature__content'}>*/}
    {/*            <h2>Create and Join Events</h2>*/}
    {/*            <p>*/}
    {/*              Save your time and energy by letting HiStaff to manage your events*/}
    {/*              (handle the colleague’s birthday, gathering, etc.)*/}
    {/*            </p>*/}
    {/*          </div>*/}
    {/*        </div>*/}
    {/*      </div>*/}
    {/*    </div>*/}

    {/*    <div className={'feature__item'}>*/}
    {/*      <div className={'row'}>*/}
    {/*        <div className={'col-6'}>*/}
    {/*          <div className={'feature__content'}>*/}
    {/*            <h2>Public Board</h2>*/}
    {/*            <p>*/}
    {/*              Let the staff write down on the board freely,*/}
    {/*              <br />*/}
    {/*              Joking with each other and writing cool content can make the*/}
    {/*              workplace more diverse and attractive for employees.{' '}*/}
    {/*            </p>*/}
    {/*          </div>*/}
    {/*        </div>*/}

    {/*        <div className={'col-6 first'}>*/}
    {/*          <div className={'thumbnail'}>*/}
    {/*            <img alt={'Board'} src={slide3} />*/}
    {/*          </div>*/}
    {/*        </div>*/}
    {/*      </div>*/}
    {/*    </div>*/}

    {/*    <div className={'feature__item'}>*/}
    {/*      <div className={'row'}>*/}
    {/*        <div className={'col-6 first'}>*/}
    {/*          <div className={'thumbnail'}>*/}
    {/*            <img alt={'News'} src={slide4} />*/}
    {/*          </div>*/}
    {/*        </div>*/}

    {/*        <div className={'col-6'}>*/}
    {/*          <div className={'feature__content'}>*/}
    {/*            <h2>Internal News</h2>*/}
    {/*            <p>*/}
    {/*              Get rid of sending news by e-mail and its problems; post the news*/}
    {/*              in an internal and attractive format via HiStaff instead.*/}
    {/*            </p>*/}
    {/*          </div>*/}
    {/*        </div>*/}
    {/*      </div>*/}
    {/*    </div>*/}

    {/*    <div className={'feature__item'}>*/}
    {/*      <div className={'row'}>*/}
    {/*        <div className={'col-6'}>*/}
    {/*          <div className={'feature__content'}>*/}
    {/*            <h2>Teams</h2>*/}
    {/*            <p>*/}
    {/*              HiStaff let you add staff in specific teams and manage them easily.*/}
    {/*            </p>*/}
    {/*          </div>*/}
    {/*        </div>*/}

    {/*        <div className={'col-6 first'}>*/}
    {/*          <div className={'thumbnail'}>*/}
    {/*            <img alt={'Team'} src={slide5} />*/}
    {/*          </div>*/}
    {/*        </div>*/}
    {/*      </div>*/}
    {/*    </div>*/}

    {/*    <div className={'feature__item'}>*/}
    {/*      <div className={'row'}>*/}
    {/*        <div className={'col-6 first'}>*/}
    {/*          <div className={'thumbnail'}>*/}
    {/*            <img alt={'Users'} src={slide6} />*/}
    {/*          </div>*/}
    {/*        </div>*/}

    {/*        <div className={'col-6'}>*/}
    {/*          <div className={'feature__content'}>*/}
    {/*            <h2>Staff management</h2>*/}
    {/*            <p>*/}
    {/*              Discard traditional ways to archive staff documents and*/}
    {/*              information, and try new ways to store and archive them in HiStaff*/}
    {/*              easily.*/}
    {/*            </p>*/}
    {/*          </div>*/}
    {/*        </div>*/}
    {/*      </div>*/}
    {/*    </div>*/}
    {/*  </div>*/}
    {/*</div>*/}
  </Layout>
);

export default DesktopPage;
